import React, {useState, useRef, useMemo} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';


import {actionAutoRefillToggle} from '../reducers';
import {descriptions} from '../data';
import Price from './PriceFormatter.js';


export const CartItem = ({count, free, title, price, iconImage, resize}) =>
<li class="quick-cart__list-item" id="mila_air_purifier">
    <div class="quick-cart__product-quantity" style={{maxWidth: '100px'}}>
        {resize === 'desktop' ?
        <input type="number" class="product-quantity__input-field mila_purifier_num_cart" step="1" min="0" value={count} autocomplete="off" /> : `${count}x`}
    </div>
    <a href="#" class="quick-cart__product-title">{iconImage && <img src={'/images/' + iconImage} style={{maxHeight: '20px', /*marginRight: '6px',*/ }}/>}{title}</a>
    <span class="quick-cart__product-price">
        {free ? <>
            <span style={{marginRight: '16px;'}}>
                <del>$<Price>{price}</Price></del>
            </span> Free </>
            : 
            <>$<Price>{price}</Price></>
        }
    </span>
</li>

export const CartTotalBlock = connect(({resize,cart:{tax,defaultFiltersById,sockCount, milaCount, autoRefill, filters: {filtersCount}, subTotal, total, existingUser}, backendCart}) => ({resize,backendCart, tax, defaultFiltersById, subTotal, total, sockCount, milaCount, autoRefill, filtersCount, existingUser}), {autoRefillToggle: actionAutoRefillToggle})(({resize,backendCart: {status, payload}, existingUser, subTotal, total, sockCount, milaCount, autoRefill, filtersCount, discount=0, tax:{tax}={}, autoRefillToggle,shipping=0, defaultFiltersById, children, ...rest }) => 
                <ul class="quick-cart__calculate">
                    <li class="quick-cart__calculate-item">
                        <strong>Subtotal</strong><span class="quick-cart__subtotal-amount">
                                $<Price>{status === 'RESOLVED' ? payload.subTotal : subTotal}</Price>
                        </span>
                    </li>
        {!!discount && 
                    <li class="quick-cart__calculate-item">
                        <strong>Discount</strong><span class="quick-cart__discount-amount"><strong>{discount.discount}</strong></span>
                    </li>}
        {console.log(tax)}
        {!!tax && 
                    <li class="quick-cart__calculate-item tax-amount">
                        <strong>Tax</strong><span class="quick-cart__tax-amount">
                            $<Price>{tax}</Price>
                        </span>
                    </li>
        }
                    <li class="quick-cart__calculate-item">
                        <strong>Shipping</strong><span class="quick-cart__shipping-amount"><strong>
                            {shipping ? <>$<Price>{shipping}</Price></>: `Free` }
                        </strong></span>
                    </li>
                    <li class="quick-cart__calculate-item quick-cart__calculate-item--total">
                        <strong>Total</strong><span class="quick-cart__total-amount"><strong>
                            $<Price>{status === 'RESOLVED' ? payload.total : total || 0}</Price>
                        </strong></span>
                    </li>
                    {children}
                </ul>)

export default connect(({resize,cart:{tax,defaultFiltersById,sockCount, milaCount, autoRefill, filters: {filtersCount}, subTotal, total, existingUser}, backendCart}) => ({resize,backendCart, tax, defaultFiltersById, subTotal, total, sockCount, milaCount, autoRefill, filtersCount, existingUser}), {autoRefillToggle: actionAutoRefillToggle})(({resize,backendCart: {status, payload}, existingUser, subTotal, total, sockCount, milaCount, autoRefill, filtersCount, discount=0, tax:{tax}={}, autoRefillToggle,shipping=0, defaultFiltersById, ...rest }) => {
    return (
        <div class="hero-block__cart-column" {...rest}>
            <div class="quick-cart__inner">
                <ul class="quick-cart__list">
                    {!!milaCount && 
                        <CartItem title="Mila Air Purifier" count={milaCount} price={descriptions[milaCount].price}/>
                    }
                    {Object.entries(filtersCount).map(([id, count]) => 
                        <CartItem title={defaultFiltersById[id].title} 
                                  count={count} 
                                  price={defaultFiltersById[id].price * count} 
                                  iconImage={defaultFiltersById[id].iconImage} free={autoRefill && !existingUser}/>)}
                    {!!sockCount && <CartItem title="Mila Sock" count={sockCount} price={sockCount*10}/>}
                </ul>
                <CartTotalBlock>
                    <li class="quick-cart__calculate-item quick-cart__calculate-item--checkbox">
                        <div class="switch-checkbox">
                            <input class="switch-checkbox__input auto-refill" id="cb2" type="checkbox" checked={autoRefill} onClick={() => console.log('aaaa')}/>
                            <label class="switch-checkbox__label" for="cb2" onClick={autoRefillToggle}></label>
                        </div>
                        <p class="switch-checkbox__text">
                            <strong>Filter Auto Refill 
                                {resize === 'desktop' && <span>
                                    <img class="auto-refill-info-img" data-class="ui-tooltip ui-corner-all ui-widget-shadow ui-widget ui-widget-content" data-style={{maxWidth: '200px'}} data-tip="Get your first filter on us. Automagically receive filters every six months from there. Being part of the club gets you free filter shipping and clean air, uninterrupted." src="/images/infobox_info_icon.svg" width="20" alt="" />
                                    <ReactTooltip />
                                </span>}
                            </strong> 
                            Seamless delivery every six months. Cancel any time - plus, get free shipping.
                        </p>
                    </li>
                </CartTotalBlock>
            </div>
        </div>)})
