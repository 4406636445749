import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {getStep, steps} from './Step'


export default connect(({resize}) => ({resize}))(( {resize, children, nextDisabled, onClick}, skip, {prevStep=steps[getStep() -1] ? `/${steps[getStep() -1]}-step` : '/', 
                                   nextStep=steps[getStep() +1] ? `/${steps[getStep() +1]}-step` : '/thanks', 
}={}) => resize === 'desktop' &&
<div class="sticky-navigation">
    <div class="container">
        <div class="sticky-navigation__inner" style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap'}}>
            <div style={{whiteSpace: 'nowrap'}}>
                {children}
            </div>
            <div class="step-navigation">
                {getStep() > 0 && 
                <Link to={prevStep} class="step-navigation__prev" ><svg class="icon icon-arrow-down"><use href="#icon-arrow-down"></use></svg></Link> }
                <Link to={nextStep} disabled={nextDisabled} class={`step-navigation__next ${nextDisabled ? 'disabled' : ''}`}
                            onClick={onClick}>{getStep() === 5 ? 'Confirm Order' :  'Next' }<svg class="icon icon-arrow-down"><use href="#icon-arrow-down"></use></svg></Link>
            </div>
        </div>
    </div>
</div>)
