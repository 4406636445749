import React from 'react';
import {connect} from 'react-redux';



import CountryDropDown from '../../components/CountryDropDown'
import QuickCart       from '../../components/QuickCart'
//import DiscountDropDown       from '../../components/DiscountDropDown'

import {getStep} from '../../components/Step';


const logo = '/images/logo.svg';


export default connect(({resize}) => ({resize}))
                      (({resize}) => 
<header className='header'>
    <div class="container">
        <div class="logo">
            <a href="http://www.milacares.com">
                <img src={logo} alt="Mila" />
            </a>
        </div>
        {resize === 'mobile' ? 
        <div class="cart-wrap">
            <QuickCart />
        </div> :
        getStep() < 5 && 
        <div class="cart-wrap">
            <CountryDropDown />
            <QuickCart />
        </div> }
    </div>
</header>
)
