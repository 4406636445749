import React from 'react';
import './App.css';
import {Router, Route, Link, Switch} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import {Provider} from 'react-redux';

import { Footer, Content} from './template/layout';
import Header from './template/layout/Header';
import {Loader} from './components/MilasBackground';

import store from './reducers'


const  logo = '/images/logo.svg';
const history = createBrowserHistory()

export default () => 
<Router history={history}>
    <Provider store={store}>
        <Header />
        <main className="main">
            <Content />
        </main>
        <Footer />
        <Loader />
    </Provider>
</Router>
