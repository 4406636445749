import React, {useState} from 'react';

const usa = '/images/usa.png';
const international = '/images/international.png';

export default ({value, onChange}) => {
    const [open, setOpen] = useState()
    return (
            <div class="country-dropdown">
                <button type="button" class="country-trigger" onClick={() => setOpen(true)}>
                        <img src={usa} alt="USA" />
                        <span>United States</span>
                        <svg class="icon icon-arrow-down">
                            <use href="#icon-arrow-down"></use>
                        </svg>
                </button>
                {open && 
                <ul class="inline-dropdown__list inline-dropdown__list--is-active">
                    <li class="inline-dropdown__list-item selected" onClick={() => setOpen(false)}>
                            <img src={usa} alt="United States" />
                            <span>United States</span> <svg class="icon icon-arrow-down"><use href="#icon-arrow-down"></use></svg></li>
                    <a href="https://mila-igg.backerkit.com/hosted_preorders">
                        <li class="inline-dropdown__list-item"><img src={international} alt="International" /><span>International</span> <svg class="icon icon-arrow-down"><use href="#icon-arrow-down"></use></svg></li></a>
                </ul>}
            </div>)
}
