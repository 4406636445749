import React, {  useEffect} from 'react';
import StickyNavigation from '../../components/StickyNavigation'
import {connect} from 'react-redux';
import {actionSockSet, actionSockInc, actionSockDec, actionSockReset} from '../../reducers';


export default connect(({cart:{sockCount, milaCount, maxStep}}) => ({sockCount, maxStep, milaCount}), {reset: actionSockReset, inc: actionSockInc, dec: actionSockDec, set:actionSockSet})
                      (({milaCount, maxStep, sockCount, inc, dec, reset, set}) => {
    useEffect(() => (sockCount === false && set(milaCount), undefined), [])
    return (
        <div className='hero-block--step3'>
            <h1 class="hero-block__title">
                Would you like to give your  Mila some socks?
            </h1>
            <div class="hero-block__description">
                <p>Mila Socks are easy to clean, extends filter life, and work with any Mila filter.</p>
            </div>
            <div class="button-card">
                <div class="button-card__item button-card__item--price">
                    <input class="button-card__radio-field" type="radio" id="f-option1" name="socks" value="10" checked={sockCount > 0} onClick={inc}/>
                    <label for="f-option1">Yes, sock it to me. <strong>+$10</strong></label>
                </div>
                <div class="button-card__item">
                    <input class="button-card__radio-field" type="radio" id="f-option2" name="socks" value="0" checked={sockCount === 0} onClick={reset}/>
                    <label for="f-option2">No thanks.</label>
                </div>
            </div>
            <StickyNavigation>
                    <div class="product-quantity">
                        <div class="product-quantity__item-number">
                            <button type="button" class="product-quantity__item-remove mila_sock_remove" onClick={dec}><svg class="icon icon-minus"><use href="#icon-minus"></use></svg></button>
                            <input type="number" class="product-quantity__input-field mila_sock_num" step="1" min="0" max="3" value={sockCount}/>
                            <button type="button" class="product-quantity__item-add mila_sock_add" onClick={inc}><svg class="icon icon-plus"><use href="#icon-plus"></use></svg></button>
                        </div>
                        <p class="bottom-info__text">
                            {sockCount ? 
                                <span>Great choice! I've added <span class="socks-quantity">{sockCount}</span> to your order for a total of $<span class="socks-amount">{sockCount * 10}</span>.</span>
                                :
                                <span>Okay, you’re the boss!</span>}
                        </p>
                    </div>
            </StickyNavigation>
    </div>)
})
