import { combineReducers} from 'redux';
import  store  from './index';


const defaultFiltersState = {milaCount: 0, filled: false, filtersCount: {}, filterCount: 0}


export const actionMilaCountSet = (count) => ({type: 'MILA_COUNT_SET', count})
export const actionMilaCountInc = (count) => ({type: 'MILA_COUNT_INC'})
export const actionMilaCountDec = (count) => ({type: 'MILA_COUNT_DEC'})

export const actionAutoRefillSet = () => ({type: 'AUTO_REFILL_SET'})
export const actionAutoRefillUnset = () => ({type: 'AUTO_REFILL_UNSET'})
export const actionAutoRefillToggle = () => ({type: 'AUTO_REFILL_TOGGLE'})

export const actionCustomerSet = (key, value) => ({type: 'CUSTOMER_SET', key, value, customerState: store.getState().customer})
export const actionCustomerUnset = (key) => ({type: 'CUSTOMER_UNSET', key})

export const actionFilterInc = id => ({type: 'FILTER_INC', id, milaCount: store.getState().cart.milaCount, autoRefill: store.getState().cart.autoRefill})
export const actionFilterDec = id => ({type: 'FILTER_DEC', id, milaCount: store.getState().cart.milaCount, autoRefill: store.getState().cart.autoRefill})

export const actionSockReset = () => ({type: 'SOCK_COUNT_RESET'})
export const actionSockSet = (count) => ({type: 'SOCK_COUNT_SET', count})
export const actionSockInc = () => ({type: 'SOCK_COUNT_INC'})
export const actionSockDec = () => ({type: 'SOCK_COUNT_DEC'})


export const actionDiscountWrong = () => ({type: 'DISCOUNT_SET', discount: "DISCOUNT_ERROR"})
export const actionDiscountReset = () => ({type: 'DISCOUNT_SET', discount: false, code: null})
export const actionDiscountCodeSet   = code => ({type: 'DISCOUNT_SET', code})
export const actionDiscountSet   = discount => ({type: 'DISCOUNT_SET', discount})

export default combineReducers({
    milaCount: (state=1,{type, count}) => {
        if (type === 'MILA_COUNT_SET'){
            return count < 0 ? 1 : count > 50 ? 50 : count
        }
        if (type === 'MILA_COUNT_INC'){
            return  state +1 > 50 ? 50 : state +1
        }

        if (type === 'MILA_COUNT_DEC'){
            return state -1 < 1 ? 1 : state -1
        }
        
        return state;
        
    },
    autoRefill: (state=true,{type}) => {
        if (type === 'AUTO_REFILL_SET'){
            return true
        }
        if (type === 'AUTO_REFILL_UNSET'){
            return false
        }
        if (type === 'AUTO_REFILL_TOGGLE'){
            return !state
        }

        return state;
        
    },
    defaultFiltersById: (state={}, {type, payload}) => {
        if (type === 'DEFAULT_FILTERS_LOAD'){
            return Object.fromEntries(payload.map(filter => ([filter.id, filter])))
        }
        return state;
    },
    filters: (state={...defaultFiltersState}, {type, milaCount, autoRefill, id}) => {
        if (type.startsWith("MILA_COUNT")) {
            return {...defaultFiltersState}
        }
        if (type === 'FILTER_INC' || type === 'FILTER_DEC'){
            const newFiltersCount = {...state.filtersCount}
            newFiltersCount[id] = newFiltersCount[id] || 0
            if (type === 'FILTER_INC'){
                if (state.filled) return state;
                newFiltersCount[id]++
            }
            if (type === 'FILTER_DEC'){
                if (--newFiltersCount[id] < 0) return state;
                if (newFiltersCount[id] === 0){
                    delete newFiltersCount[id]
                }
            }
            const filterCount = Object.entries(newFiltersCount).reduce((count, [key, c]) => count +c, 0)
            const filled      = filterCount === milaCount // || !autoRefill ???
            return {
                filled,
                filterCount,
                filtersCount:newFiltersCount
            }
        }

        return state;
    }, 
    sockCount: (state=false,{type, count=0}) => {
        if (type.startsWith("MILA_COUNT")) {
            return false
        }

        if (type === 'SOCK_COUNT_RESET'){
            return 0
        }
        if (type === 'SOCK_COUNT_INC'){
            return  state +1 > 50 ? 50 : state +1
        }

        if (type === 'SOCK_COUNT_DEC'){
            return state -1 < 0 ? 0 : state -1
        }

        if (type === 'SOCK_COUNT_SET'){
            return count
        }
        return state;
    }, 
    customer: (state={},{type, key, value}) => {
        if (type === 'CUSTOMER_SET'){
            return {
                ...state,
                [key]: value
            }
        }

        if (type === 'CUSTOMER_UNSET'){
            const {[key]: skip, ...rest} = state
            return rest;
        }
        
        return state;
        
    },
    discount: (state={code: '', discount: undefined}, {type, discount, code}) =>{
        if (type === 'DISCOUNT_SET'){
            return {discount: discount === undefined ? state.discount : discount, 
                    code:     code === undefined ? state.code : code}
        }

        return state;
    }
})
