import React, {useState, useRef, useMemo, useEffect} from 'react';
import {Redirect, Route, Link, Switch } from 'react-router-dom';
import Slider from 'react-slick';
import StickyNavigation from '../../components/StickyNavigation'
import MobileMilaSlider, {Item} from '../../components/MobileMilaSlider.js'
import {connect} from 'react-redux';
import {actionMilaCountInc, actionMilaCountDec, actionMilaCountSet, actionSockSet} from '../../reducers';

import {descriptions} from '../../data';

export default connect(({cart:{milaCount},resize}) => ({milaCount, resize}), {setSocks:actionSockSet,set:actionMilaCountSet, inc: actionMilaCountInc, dec: actionMilaCountDec})
                      (({milaCount, set, inc, dec ,setSocks, resize}) => {
    useMemo(() => { 
        setTimeout(() => {
            const items = document.querySelectorAll('.card-list__item')
            if (items &&  items[milaCount -1]){
                items[milaCount -1/* + (resize === 'mobile' ? 1 : 0)*/].click()
            }
        },200)
    },[milaCount])
    return (
    <>
        <h1 class="hero-block__title">How many Milas would you like?</h1>
        <div class="hero-block__description">
            <p>We recommend one for every room or space you spend a decent amount of time breathing in.</p>
        </div>
        {resize === 'mobile' ? <MobileMilaSlider /> :
        <Slider className="card-list card-list__slider" {...{initialSlide: milaCount,// < 0 ? milaProdCount -1 : milaProdCount -2,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            value: milaCount,
            vertical: true,
            verticalSwiping: true,
            speed: 300,
            pauseOnHover: false,
            pauseOnFocus: false,
            draggable: true,
            focusOnSelect: true,
            centerMode: true,
            centerPadding: '-200px',
            responsive: [{
                breakpoint: 767,
                settings: {
                vertical: false,
                verticalSwiping: false,
                slidesToShow: 4,
                slidesToScroll: 1
                }
            }],
            afterChange(current){
                set(current +1)
            }}}>
        {descriptions.slice(1).map((mila, count) => <Item {...mila} count={count +1}/>)}
        </Slider>}
        <StickyNavigation nextDisabled={milaCount > 10}>
            <div class="product-quantity">
                <div class="product-quantity__item-number">
                    <button type="button" class="product-quantity__item-remove mila_purifier_remove" onClick={dec}><svg class="icon icon-minus"><use href="#icon-minus"></use></svg></button>
                    <input type="number" class="product-quantity__input-field mila_purifier_num" step="1" min="0" max="3" value={milaCount} autocomplete="off" />
                    <button type="button" class="product-quantity__item-add mila_purifier_add" onClick={inc}><svg class="icon icon-plus"><use href="#icon-plus"></use></svg></button>
                </div>
                <p class="product-quantity__message">{descriptions[milaCount].ticker}</p>
            </div>
        </StickyNavigation>
    </>
)})
