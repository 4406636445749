import React, {useState, useRef, useMemo} from 'react';
import {connect} from 'react-redux';
import {taxes} from 'm2back/cart';

export default connect(({cart: {customer: {differentBillingAddress, shippingState, billingState}}, backendCart: {status, payload: backendCart}}) => ({usState: differentBillingAddress ?  billingState : shippingState, backendStatus: status === 'RESOLVED', backendCart }))(({usState, children, backendStatus, backendCart}) => 
<span style={{color: backendStatus ? '' : 'gray'}}>
    {(children = +children, null)}
    {children && children != Infinity && children != -Infinity ? (Object.keys(taxes).includes(usState) ? children.toFixed(2): children.toFixed()) : '0'}
</span>

)
