import React, {useState, useRef, useMemo} from 'react';

export default () =>
<section class="message-block">
    <h1>Clean air is on the way 🙌</h1>
    <figure class="message-block__image">
        <img src="/images/joyful-girl.gif" alt="Girl" />
    </figure>
    <div class="message-block__content">
        <p>Thanks for your order, User! We've got your order and your  Milas are officially reserved for our next batch — dropping  October 16. ✨ Watch your inbox for the latest updates.</p>
    </div>
</section>
