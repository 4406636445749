import React from 'react';
import {connect} from 'react-redux';
import {CardElement, Elements, PaymentRequestButtonElement} from '@stripe/react-stripe-js';
import Cart, {CartTotalBlock} from '../../components/Cart'
import BlackButton from '../../components/BlackButton';
import {stripePromise } from '../../reducers';




import StickyNavigation from '../../components/StickyNavigation'

import {actionCustomerSet, actionOrder, actionPaymentMethodApple, actionPaymentMethodGoogle, actionPaymentMethodStripe} from '../../reducers';
import Step from '../../components/Step'


const CPaymentRequestButtonElement = connect(({paymentRequest}) => ({options:paymentRequest}))(({options}) => options ? <PaymentRequestButtonElement options={options}/> : null)

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export const MobileSixthStep = connect(s => ({cart: s.cart, resize: s.resize}), {set: actionCustomerSet, createOrder: actionOrder})(({cart, set, createOrder, resize}) =>{
    const {maxStep} = cart
    return (
        <div style={{display: 'flex', justifyContent: 'space-between',  ...(maxStep < 6 ? {opacity: 0.6 - (6 - maxStep)*0.1} : {}), flexDirection: resize === 'mobile' ? 'column' : '' }}>
            <h1 class="hero-block__title">Add your credit or debit card.</h1>
            <div class="hero-block__description">
                <p>It's risk free with our 100% Mila-backed guarantee.
                </p>
            </div>
            <div style={{height: '70px'}} /> 
            <PaymentBlock />
            <div style={{height: '33px'}} /> 
            <h2 className='form-title'>
                Order Summary
            </h2>
            <div className='form-row'>
                <CartTotalBlock />
            </div>
            <div style={{height: '70px'}} /> 
            <button className='cyan-button'>Pay ${cart.total}</button>
        </div>)
    })

export const PaymentBlock = () => 
    <Elements stripe={stripePromise}>
        <div className='payment-block__form'>
                <div>
                        <h2 class="form-title">Payment information</h2>
                        <div class="form-row">
                            <label for="cname">Name on card</label>
                            <input type="text" id="cname" name="cardname" class="form-control" placeholder="Cardholder Name" required />
                        </div>
                        <div class="form-row">
                            <label for="card-element">
                            Credit or debit card
                            </label>
                            <div id="card-element">
                                <CardElement />
                            </div>

                            <div id="card-errors" role="alert"></div>
                        </div>
                </div>
            </div>
    </Elements>

export default connect(s => ({cart: s.cart, resize: s.resize}), {apple: actionPaymentMethodApple, google: actionPaymentMethodGoogle, stripe: actionPaymentMethodStripe, set: actionCustomerSet, createOrder: actionOrder})
                        (({cart, set, createOrder, resize, apple, google, stripe}) =>{
    const {maxStep} = cart
    return (
        <div style={{display: 'flex', justifyContent: 'space-between',  ...(maxStep < 6 ? {opacity: 0.6 - (6 - maxStep)*0.1} : {}), flexDirection: resize === 'mobile' ? 'column' : '' }}>
        {resize === 'desktop' && <>
            <div className='hero-block__form-column'>
                <Step />
                <h1 class="hero-block__title">We’re almost done!</h1>
                <div class="hero-block__description">
                    <p>Carefully review your order and enter your payment info so we can make all your clean air dreams come true. </p>
                </div>
                <PaymentBlock />
            </div></>}
            <Cart />
            <div className='button-block'>
                <BlackButton onClick={apple}>
                    <img src='/images/apple.svg'/>Pay
                </BlackButton>
                <CPaymentRequestButtonElement />
                <BlackButton onClick={google}>
                    <img src='/images/google.svg'/>Pay
                </BlackButton>
                <BlackButton onClick={stripe}>
                    Credit or Debit Card
                </BlackButton>
            </div>
            <StickyNavigation onClick={(e) => (e.preventDefault(), createOrder(cart))}>
            </StickyNavigation>
        </div>)
})

