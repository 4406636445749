import React, {useState, useRef, useMemo} from 'react';

export const descriptions = [,
                      {"ticker":"A perfect start.","comment":<>Ideal for a studio apartment or any room up to 600 ft<sup>2</sup>.</>,"price":"299"},
                      {"ticker":"Aw, twins.","comment":<>Perfect for a 1-bedroom apartment or any 2 rooms up to 600 ft<sup>2</sup> each.</>,"price":"548","save":"50"},
                      {"ticker":"Hey, good things come in threes.","comment":<>Superb for a 2-bedroom apt. or any 3 rooms up to 600 ft<sup>2</sup> each.</>,"price":"822","save":"75"},
                      {"ticker":"Ooo, now we’re talkin’.","comment":<>Fantastic for any 4 rooms up to 600 ft<sup>2</sup> each.</>,"price":"1096","save":"100"},
                      {"ticker":"You’re making us blush.","comment":<>Stellar for any 5 rooms up to 600 ft<sup>2</sup> each.</>,"price":"1370","save":"125"},
                      {"ticker":"Well, this relationship is moving rather quickly.","comment":<>Spectacular for any 6 rooms up to 600 ft<sup>2</sup> each.</>,"price":"1644","save":"150"},
                      {"ticker":"It must be love.","comment":<>Amazing for any 7 rooms up to. 600 ft<sup>2</sup> each.</>,"price":"1918","save":"175"},
                      {"ticker":"This feels so…brazen.","comment":<>Wonderful for any 8 rooms up to 600 ft<sup>2</sup> each.</>,"price":"2192","save":"200"},
                      {"ticker":"Sure, one more, why not?","comment":<>Awesome for any 9 rooms up to 600 ft<sup>2</sup> each.</>,"price":"2466","save":"225"},
                      {"ticker":"Whew…we’re getting lightheaded…","comment":<>Just great for any 10 rooms up to. 600 ft<sup>2</sup> each.</>,"price":"2740","save":"250"},
                      {"ticker":"Whoa there! That’s a bit more than we can handle here. Please contact Mila Customer Care to place your cusom order.","comment":<>Please contact <a href="mailto:support@milacares.com">Mila Customer Care</a> to place your cusom order.</>,"price":"Pricing on demand"},
                      {"ticker":"More? For reals?","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing on demand"},
                      {"ticker":"Don’t get us wrong, we’re all about it.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing on demand"},
                      {"ticker":"We like you, too. A lot.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"A LOT a lot.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"This is bringing up a lot of questions for us, though.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"This is bringing up a lot of questions for us, though.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"What would one even do with 18 air purifiers?","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"You’re not gonna sell these on the black market, are you?","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Good.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"We didn’t think so.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Had to ask.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"Anyway.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"We’re tickled that you’re still here.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"At this point you’re probably waiting for something.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Some big punchline.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"Maybe a discount code.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Or a million dollars.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"Are we right?","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Ok, ok. Twist our arm.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"Here’s a code for an additional 10% off your order: XXXXX","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Just enter it on the final checkout screen. You’re welcome.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"But first, you need to pick your number of Milas.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"It’s not really 34, is it?","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"That’d be kinda crazy.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"Go ahead and place your order.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
                      {"ticker":"It’s been lovely chatting with you.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},{"ticker":"It’s been lovely chatting with you.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"You should too…","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"TO PLACE YOUR MILA ORDER.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"That is the reason you’re here, isn’t it?","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"SIGH.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"You’re obviously easily distracted.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"We have no choice but to abruptly end this.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"So go place that order and we’ll talk soon.","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"THE END","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"SERIOUSLY","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"THIS IS THE END","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"THIS IS THE END","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"},
    {"ticker":"THIS IS THE END","comment":"Please contact <a href=\"mailto:support@milacares.com\">Mila Customer Care</a> to place your cusom order.","price":"Pricing","save":"on demand"}]


export const defaultFilters = [
    {image: '/basic-breather-filter.png', 
        iconImage: '/leaf-fluttering-in-wind.png',
        title: 'Basic Breather', 
        shortDescription: 'For the everyday lover of clean air',
        header: 'H12 HEPA',
        description: 'The filter every family needs: Certified HEPA to trap harmul particulartes.', 
        price: 59, 
        color: '#3E4649', 
        count: 0, 
        id: 4
    },
    {image: '/big-sneeze-filter.png', 
        iconImage: '/sneezing-face.png',
        title: 'Big Sneeze', 
        shortDescription: 'Fight back against annoying allergens',
        header: 'H12 HEPA + Anti-allergen coating',
        description: 'Helps allergy sufferers wake up with clearer heads.', 
        price: 69, 
        color: '#FFC140', 
        count: 0, 
        id: 5
    }
]

export const defaultFiltersById = Object.fromEntries(defaultFilters.map(filter => ([filter.id, filter])))

