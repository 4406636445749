import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';

import {actionMilaCountSet} from '../reducers';
import {descriptions} from '../data';
import Price from './PriceFormatter.js';


export const Item = ({count, comment, price, save, selected, ...rest}) => 
<div className={`slick-slide slick-active slick-center ${selected ? "slick-current" : ''}`}  aria-hidden="false" style={{outline: "none", width: "188px"}} {...rest}>
            <div class="card-list__item" >
                <span class="tick-box">
                    <svg class="icon icon-tick"><use href="#icon-tick"></use></svg>
                </span>
                <h2 class="card-list__title"><span>{count}x</span> <strong>Mila{count > 1 && 's'}</strong></h2>
                <p class="card-list__description">{count > 10 ? <> Please contact <a href="mailto:support@milacares.com">Mila Customer Care</a> to place your custom order.</>
                    : comment}</p>
                <span class="card-list__price"><strong>{count > 10 ? 'Pricing' : '$' + price}</strong>{count > 10 ? 'on demand' : save && `Save $${save}`}</span>
            </div>
</div>


export default connect(({cart:{milaCount}}) => ({milaCount}), {set: actionMilaCountSet})(({milaCount, set, ...rest}) => {
    //<div class="slick-track" style="opacity: 1; transform: translate3d(0px, 0px, 0px); width: 9400px;">
    const timeout       = useRef()
    const el            = useRef()
    const autoscroll    = useRef()
    const inTouch       = useRef()
    const nextClickSkip = useRef()

    const [current,setCurrent] = useState(0)

    const scrollToCurrent = () => {
        if (autoscroll.current || !el.current) return;

        const {scrollWidth} =  el.current;
        const viewPortWidth             =  el.current.getBoundingClientRect().width
        const itemWidth = scrollWidth/(descriptions.length -1)
        let endScroll = current * itemWidth - (viewPortWidth - itemWidth)/2

        if (endScroll < 0) endScroll = 0
        if (endScroll > scrollWidth - viewPortWidth) endScroll = scrollWidth - viewPortWidth 


        const step = () => {
            if (!el.current) return;

            autoscroll.current = true
            const {scrollLeft} =  el.current;
            const diff = (endScroll - scrollLeft  ) /5
            if (Math.abs(diff) > 1){
                el.current.scrollTo(scrollLeft + diff, 0)
                setTimeout(step, 20)
            }
            else {
                el.current.scrollTo(endScroll, 0)
                autoscroll.current = false
            }
        }
        step()
        if (milaCount !== current +1) set(current +1)
    }

    const onScroll = e => { 
        if (autoscroll.current || inTouch.current) return;
        const {scrollLeft, scrollWidth} =  el.current;
        const viewPortWidth             = el.current.getBoundingClientRect().width

        if (timeout.current) clearInterval(timeout.current)
        timeout.current = setTimeout(() => {
            //console.log('ON SCROLL', scrollLeft, el.current)
            const itemWidth = scrollWidth/(descriptions.length -1)
            let newCurrent;
            if (scrollLeft === 0) newCurrent = 0
            else if (scrollLeft >= scrollWidth - viewPortWidth) newCurrent = (descriptions.length -2)
            else newCurrent = (Math.floor((scrollLeft + viewPortWidth/2) / itemWidth))

            setCurrent(newCurrent)
            if (newCurrent === current) scrollToCurrent()

        }, 200)
    }

    useEffect(scrollToCurrent, [current])
    useEffect(() => setCurrent(milaCount -1), [milaCount])
    return (
        <div class="slick-slider card-list card-list__slider slick-initialized" dir="ltr">
            <div class="slick-list">
                <div class="slick-track" ref={el} style={{overflow: 'auto'}} onScroll={onScroll} onTouchStart={() => inTouch.current = performance.now()} onTouchEnd={e => {
                        const {current: startTime} = inTouch
                        inTouch.current = false
                        if ((performance.now() - startTime)> 500){
                            onScroll(e)
                            nextClickSkip.current = true
                        }
                    }}>
                    {descriptions.slice(1).map((mila, count) => <Item {...mila} count={count +1} selected={current === count} onClick={() => (!nextClickSkip.current && setCurrent(count), nextClickSkip.current = false)}/>)}
                </div>
            </div>
        </div>
    )
})
