import React from 'react';
import {connect} from 'react-redux'
import {getStep} from '../Step.js'

export const Loader = () =>
    <div style={{position: 'absolute', display: 'none'}}>
        <img src='/images/1x-mila.jpg'/>
        <img src='/images/2x-mila.jpg'/>
        <img src='/images/3x-mila.jpg'/>
        <img src='/images/4x-mila.jpg'/>
        <img src='/images/hero-image4.jpg'/>
        <img src='/images/hero-image5.jpg'/>
    </div>

export default connect(s => ({count: s.cart.milaCount}))(({count=1}) =>
<>
<ul class="background background--step1">
    <li class="background__image lazy background__image--is-active" style={{backgroundImage: `url("/images/${count > 4 ? 4 : count}x-mila.jpg")`}}>
    </li>
</ul>
</>)
