import React from 'react';
import {Redirect, Route, Link, Switch } from 'react-router-dom';

export const steps = ['', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh']
export const getStep = ({location:{pathname}}=window) => pathname === '/' ? 0 : steps.indexOf(pathname.slice(1, pathname.indexOf('-')))

export default ({step}) =>
<>
    <span class="hero-block__step">Step 0{step || (getStep() +1)}</span> 
</>

