import React  from 'react';
import StickyNavigation from '../../components/StickyNavigation'
import {connect} from 'react-redux';
import { actionAutoRefillUnset, actionAutoRefillSet} from '../../reducers';

export default connect(({cart:{autoRefill,maxStep}}) => ({autoRefill, maxStep}), {set:actionAutoRefillSet, unset:actionAutoRefillUnset})
                      (({maxStep, autoRefill, set, unset}) =>
<div className='hero-block--step2'>
        <h1 class="hero-block__title">Would you like to subscribe and get your first filters for free?</h1>
        <div class="hero-block__description">
            <p>Auto-refill makes filter delivery simple and seamless, with a fresh filter delivered to your door every six months. Cancel any time, save on shipping, and get your first filter on us.</p>
        </div>
        <div class="button-card">
            <div class="button-card__item">
                <input class="button-card__radio-field" type="radio" id="radio-option1" name="subscribe" value="1" checked={autoRefill} onChange={set}/>
                <label for="radio-option1">Yes, sign me up! One less thing to think about.</label>
            </div>
            <div class="button-card__item">
                <input class="button-card__radio-field" type="radio" id="radio-option2" name="subscribe" value="0"  checked={!autoRefill} onChange={unset}/>
                <label for="radio-option2">No thanks. I’ll order filters manually.</label>
            </div>
        </div>
        <StickyNavigation>
            <div class="bottom-info" style={{minWidth: '300px'}}>
                <p class="bottom-info__text">
                    {autoRefill ?
                        <span><strong>Yay!</strong> Welcome to the club  🙌</span> :
                        <span><strong>Are you sure?</strong> 🤔 Auto Refill makes filter replacements a breeze and you can cancel any time. Plus, you'll get your  first filter free.</span>
                    }
                </p>
            </div>
        </StickyNavigation>
</div>)
