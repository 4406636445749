import React from 'react';
import StickyNavigation from '../../components/StickyNavigation'
import {connect} from 'react-redux';
import {actionFilterInc, actionFilterDec} from '../../reducers';

const FilterCardQuantity = connect(({cart:{filters}}) => ({filters}), {inc: actionFilterInc, dec: actionFilterDec})(({id, filters:{filtersCount}, inc, dec, color}) =>
            <div class="card-block__product-quantity" onClick={e => e.stopPropagation()}>
                <button type="button" class="product-quantity__item-remove prod_remove" style={{color}} onClick={() => dec(id)}><svg class="icon icon-minus"><use href="#icon-minus"></use></svg></button>
                <input type="number" class="product-quantity__input-field prod_num" step="1" min="0" autocomplete="off" value={filtersCount[id] || 0} />
                <button type="button" class="product-quantity__item-add prod_add" style={{color}} onClick={() => inc(id)}><svg class="icon icon-plus"><use href="#icon-plus"></use></svg></button>
            </div>)


const FilterCard = connect(({cart:{autoRefill, filters: {filtersCount}}}) => ({autoRefill, filtersCount}), {inc: actionFilterInc})(({filtersCount, autoRefill, filter:{image, iconImage, title, header, description, shortDescription, price, color, count, id}, inc, dec}) =>
    <div class="card-block__item lazy" style={{boxShadow:" 0 0 0 4px transparent inset",backgroundImage:`url("${image}")`, border: `${filtersCount[id] ? `4px solid ${color}` : '4px solid transparent'}`}} onClick={() => inc(id)}>
        <div class="card-block__header">
            <h2 class="card-block__title" style={{color}}>The 
                <img src={'/images/' + iconImage} alt="leaf-fluttering-in-wind" /> <br/>
                {title}
            </h2>
            <h3 class="card-block__subtitle">{shortDescription}</h3>
        </div>
        <div class="card-block__content">
            <p>{header}</p>
        </div>
        <div class="card-block__bottom-content">
            {description}
        </div>
        <div class="card-block__footer">
    { autoRefill ?
                    <span class="card-block__item-price card-block__item-price--free">
                                <strong>Free</strong>
                                    <del style={{color}}>${price}
                                    </del>
                    </span> :
                    <span class="card-block__item-price">${price}</span> }
                    <FilterCardQuantity id={id} color={color}/>
        </div>
    </div>
)

const Filters = connect(({cart:{defaultFiltersById:filters}}) => ({filters}))(({filters}) =>
<div class="card-block">
    {Object.entries(filters).map(([id,filter]) => <FilterCard filter={filter} />)}
</div>)

export default connect(({cart:{autoRefill, maxStep, milaCount, filters:{filterCount}}}) => ({milaCount,filterCount, autoRefill, maxStep}))(({autoRefill, filterCount, milaCount, set, unset, maxStep}) =>
<div className='hero-block--step3' style={maxStep < 3 ? {opacity: 0.6 - (3 - maxStep)*0.1} : {}}>
        <h1 class="hero-block__title">Now choose <u>{milaCount}</u> custom filters</h1>
        <Filters />
        <div style={{minHeight: '100px'}} />
    <StickyNavigation nextDisabled={autoRefill && filterCount !== milaCount}>
            <div class="bottom-info" style={{minWidth: '500px'}}>
                    <p class="bottom-info__text"><strong>{filterCount ? <>{" 🍃".repeat(filterCount)    }{filterCount} filter{filterCount > 1 ? 's' : ''} selected!{milaCount - filterCount ?  ` You need to pick ${milaCount - filterCount} more` : ''}</> : <>Please choose {milaCount} filter{milaCount > 1 && 's'}...</>}</strong></p>
            </div>
    </StickyNavigation>
</div>)
