import React from 'react';
import {connect} from 'react-redux';
import Step  from '../../components/Step'
import MilasBackground from '../../components/MilasBackground'
import Route            from '../../components/ReduxRoute'

import { Switch} from 'react-router-dom';

import FirstStep from '../pages/FirstStep'
import SecondStep from '../pages/SecondStep'
import ThirdStep from '../pages/ThirdStep'
import FourthStep from '../pages/FourthStep'
import FifthStep from '../pages/FifthStep'
import SixthStep, {MobileSixthStep} from '../pages/SixthStep'
import SeventhStep from '../pages/SeventhStep'
import Footer      from './Footer'
export {Footer}



const ContentRoutes = () =>
<div className='container'>
    <Step />
    <Route path='/' component={FirstStep} exact/>
    <Route path='/third-step' component={ThirdStep}/>
    <Route path='/fifth-step' component={FifthStep}/>
    <Route path='/seventh-step' component={SeventhStep}/>
</div>

const DesktopPages = () =>
<>
        <Route path='/' component={MilasBackground} exact />
        <Switch>
            <Route path='/second-step' component={() =>
                <section class='hero-block' style={{backgroundImage: 'url(/images/hero-image2.jpg)', paddingTop: '0px'}}>
                    <div className='container'>
                        <Step />
                        <SecondStep />
                    </div>
                </section>}/>
            <Route path='/fourth-step' component={connect(({cart:{sockCount}}) =>({sockCount}))(({sockCount}) =>
                <section class='hero-block' style={{backgroundImage: `url(/images/hero-image${sockCount ? 5 : 4}.jpg)`}}>
                    <div className='container'>
                        <Step />
                        <FourthStep />
                    </div>
                </section>)} />
            <Route path='/sixth-step' component={() =>
                <section class='hero-block hero-block__step6'>
                    <SixthStep />
                </section>} />
            <ContentRoutes />
        </Switch>
</> 

const MobilePages = connect(({ cart, paymentMethod}) => ({ cart, paymentMethod}))
                            (({ cart, paymentMethod}) => {
    const pages = [
            <>
                <div style={{height: '100px'}} />
                <Step step={1}/>
                <FirstStep />
            </>,
            <>
                <Step step={2}/>
                <SecondStep />
            </>,
            <>
                <div style={{height: '100px'}} />
                <Step step={3}/>
                <ThirdStep />
            </>,
            <>
                <Step step={4}/>
                <FourthStep />
            </>,
            <>
                <div style={{height: '100px'}} /> 
                <Step step={5}/>
                <SixthStep />
            </>,
            <>
                <div style={{height: '100px'}} /> 
                <Step step={6}/>
                <FifthStep />
            </>,
            <>
                <div style={{height: '100px'}} /> 
                <Step step={7}/>
                <MobileSixthStep />
            </>,
            <>
                <SeventhStep />
            </>];
    return (
    <div className='container'>
        {pages.filter((skip, i) => i < cart.maxStep).filter((skip, i) => paymentMethod === 'stripe' || (i !== 5))}
    </div>
    )
})

export const Content = connect(({resize}) => ({resize}))(({resize}) => 
    resize === 'desktop' ? <DesktopPages /> :  <MobilePages />
)

