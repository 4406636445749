import React, {useState} from 'react';
import {connect}         from 'react-redux';
import Cart              from './Cart'
import Price             from './PriceFormatter'

export default connect(({cart:{total}}) => ({total}))(({total}) => {
    const [open, setOpen] = useState()
    return ( <div class="quick-cart">
                <button type="button" class={`cart-trigger ${open ? 'cart-trigger--is-active' : ''}`} onClick={() => setOpen(!open)}>
                    <svg class="icon icon-basket">
                        <use href="#icon-basket"></use>
                    </svg>
                    <span id="cart_total">
                        {open ? 'Close' : <>$ <Price>{total}</Price></>}
                        </span>
                    <svg class="icon icon-arrow-down"><use href="#icon-arrow-down"></use></svg>
                </button>
                {open && <>
                    <Cart className="quick-cart__wrap quick-cart__wrap--is-active" /> 
                    <div style={{position: 'fixed',left: 0, top: 0, right: 0, bottom: 0, background: 'rgba(1,1,1,0.6)'}} onClick={() => setOpen(false)}>
                    </div></> }
            </div>
    )
})

