import React, {useState, useRef, useMemo, useEffect} from 'react';
import {Redirect, Route, Link, Switch } from 'react-router-dom';
import StickyNavigation from '../../components/StickyNavigation'
import {connect} from 'react-redux';
import {actionCustomerSet} from '../../reducers';
import PlacesAutocomplete,{geocodeByAddress} from 'react-places-autocomplete';

import orderSchema from 'm2back/schemas/json-schema/order/schemas/post.js'


delete orderSchema.properties.discount

const Ajv         = require('ajv')
const ajv         = new Ajv

const validator = ajv.compile(orderSchema)

String.prototype.replaceAll = function(regex, newOne){
    let copy = this, newStr;
    while ((newStr = copy.replace(regex, newOne)) !== copy){
        copy = newStr
    }
    return newStr
}

const addPhoneFormatting = str => (
    str = str.replaceAll(/\D/, ''),
    str = str.slice(0,10),
    str += '_'.repeat(10 - str.length),
    '(' + [...str].reduce( (a,b,i) => a + (({3: ') ', 6: '-'})[i] || '') + b)
    )





const validate = ({autoRefill, customer: {billingAddress, billingApartment, billingCity, billingState, billingZipcode, email:customerEmail, fullName: customerFullName, phoneNumber: customerPhoneNumber,shippingAddress, shippingApartment, shippingCity, shippingState, shippingZipcode  }, milaCount,  sockCount, filters: {filtersCount}, }) => {
    const data = {autoRefill, billingAddress, billingApartment, billingCity, billingState, billingZipcode, customerEmail, customerFullName, customerPhoneNumber,   milaCount, shippingAddress, shippingApartment, shippingCity, shippingState, shippingZipcode, sockCount, filtersCount}
    if(!validator(data)){
        let {dataPath}= validator.errors[0]
        dataPath = dataPath.slice(1)
        if (!data[dataPath]){
            validator.errors[0].keyword = 'required'
        }
        //console.log(validator.errors, data)
        return validator.errors
    }
}

const getTextFromDataPath = dataPath => [...dataPath]
                            .reduce((a, b) => (b <= 'Z' || a) ? 
                            a + (((a && (b <= 'Z')) ? 
                                                ' ' : '') + 
                                        b.toLowerCase())      : a, '').replace('customer', '').replace('.', '')


const BillingForm = connect(({cart:{customer}}) => ({customer}), {set:actionCustomerSet})
                            (({customer, set , errors}) =>
<form id="billing-form">
    <div class={`form-group form-group--zindex3 ${errors && errors[0].dataPath === '.billingAddress' ? 'form-group--error' : '' }`} >
        <input name="billing_address" id="billing-address" type="text" class="form-control" placeholder="Type your address here ..." value={customer.billingAddress} onChange={e => set('billingAddress', e.target.value)} required />
        <label for="billing-address">Address</label>
    </div>
    <div class={`form-group form-group--zindex3 ${errors && errors[0].dataPath === '.billingApartment' ? 'form-group--error' : '' }`} >
        <input name="billing_apartment" id="billing-apartment" type="text" class="form-control" placeholder="(Optional)" value={customer.billingApartment} onChange={e => set('billingApartment', e.target.value)} required />
        <label for="billing-apartment">Unit/Apartment #</label>
    </div>
    <div class={`form-group form-group--zindex2 ${errors && errors[0].dataPath === '.billingCity' ? 'form-group--error' : '' }`} >
        <input name="billing_city" id="billing-city" type="text" class="form-control" placeholder="Type your city here ..." value={customer.billingCity} onChange={e => set('billingCity', e.target.value)} required />
        <label for="billing-city">City</label>
    </div>
    <div class="form-group__column form-group--zindex1">
        <div class={`form-group form-group--two-third ${errors && errors[0].dataPath === '.billingState' ? 'form-group--error' : '' }`}>
            <input name="billing_state" id="billing-state" type="text" class="form-control" placeholder="State" value={customer.billingState} onChange={e => set('billingState', e.target.value)} required />
            <label for="billing-state">State</label>
        </div>
        <div class={`form-group form-group--one-third ${errors && errors[0].dataPath === '.billingZipcode' ? 'form-group--error' : '' }`}>
            <input name="billing_zipcode" id="billing-zipcode" type="number" class="form-control" placeholder="Zipcode" value={customer.billingZipcode} onChange={e => set('billingZipcode', e.target.value)} required />
            <label for="billing-zipcode">Zipcode</label>
        </div>
    </div>
</form>)

const ShippingForm = connect(({cart:{customer}}) => ({customer}), {set:actionCustomerSet})
                            (({customer, set, errors}) =>
<form id="shipping-form">
    <div class={`form-group form-group--zindex3 ${errors && errors[0].dataPath === '.shippingAddress' ? 'form-group--error' : '' }`}>
        <PlacesAutocomplete value={customer.shippingAddress} onError={(...params) => console.log(params)}>
 {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => <>
     {false/*console.log({ getInputProps, suggestions, getSuggestionItemProps, loading })*/}
            <input name="shipping_address" id="shipping-address" type="text" class="form-control" placeholder="Type your address here ..." value={customer.shippingAddress} onChange={e => set('shippingAddress', e.target.value)} required />
            <label for="shipping-address">Address</label> </>}
        </PlacesAutocomplete>
    </div>
    <div class={`form-group form-group--zindex3 ${errors && errors[0].dataPath === '.shippingApartment' ? 'form-group--error' : '' }`}>
        <input name="shipping_apartment" id="shipping-apartment" type="text" class="form-control" placeholder="(Optional)" value={customer.shippingApartment} onChange={e => set('shippingApartment', e.target.value)} required />
        <label for="shipping-apartment">Unit/Apartment #</label>
    </div>
    <div class={`form-group form-group--zindex2 ${errors && errors[0].dataPath === '.shippingCity' ? 'form-group--error' : '' }`}>
        <input name="shipping_city" id="shipping-city" type="text" class="form-control" placeholder="Type your city here ..." value={customer.shippingCity} onChange={e => set('shippingCity', e.target.value)}  required />
        <label for="shipping-city">City</label>
    </div>
    <div class="form-group__column form-group--zindex1">
        <div class={`form-group form-group--two-third ${errors && errors[0].dataPath === '.shippingState' ? 'form-group--error' : '' }`}>
            <input name="shipping_state" id="state" type="text" class="form-control" placeholder="State..." value={customer.shippingState} onChange={e => set('shippingState', e.target.value)}  required />
            <label for="state">State</label>
        </div>
        <div class={`form-group form-group--one-third ${errors && errors[0].dataPath === '.shippingZipcode' ? 'form-group--error' : '' }`}>
            <input name="shipping_zipcode" id="zipcode" type="text" class="form-control" placeholder="Zipcode..." value={customer.shippingZipcode} onChange={e => set('shippingZipcode', e.target.value)}  maxlength="5" required />
            <label for="zipcode">Zipcode</label>
        </div>
    </div>
</form>)


export default connect(s => ({customer: s.cart.customer, cart: s.cart}), {set: actionCustomerSet})
                        (({customer, set, cart}) =>{
    const [toggle, setToggle] = useState('shipping')
    const [errors, setErrors] = useState()
    const {maxStep} = cart
    useEffect(() => (setErrors(validate(cart))), [cart])

    const weekDays = ['Sunday', 'Monday', 'Tuesday' , 'Wednesday', 'Thursday','Friday', 'Saturday']
    const monthes  =   ['Jan', 'Feb', 'Mar' , 'Apr', 'May','Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const d        = new Date(((new Date()).getTime()) + 7 * 86400000)
    const deliveryStr = `${weekDays[d.getDay()]}, ${monthes[d.getMonth()]} ${d.getDate()}`

    const phoneChange = e => {
        const element = e.target
        const newValue = addPhoneFormatting(e.target.value)

        const {phoneNumber=addPhoneFormatting('')} = customer

        let caret   = element.selectionStart

        if (newValue.replaceAll(/\D/,'').length < phoneNumber.replaceAll(/\D/, '').length){ //some number removed
            while (caret > 0 && !phoneNumber[caret-1].match(/[1234567890_]/)) //move cursor left to last number or _
                caret--
        }
        if (((newValue.replaceAll(/\D/,'').length > phoneNumber.replaceAll(/\D/, '').length) ||  //new number added OR
            (!newValue.includes('_') &&  !phoneNumber.includes('_'))) && caret && caret <= newValue.length) //new number inserted
            while (!phoneNumber[caret-1].match(/[1234567890_]/)) //move to next number or _
                caret++

        set('phoneNumber', newValue)
        window.requestAnimationFrame( () => {
            element.selectionStart = caret
            element.selectionEnd = caret
        })
    }
    
    return (
        <div className='hero-block--step5' style={maxStep < 5 ? {opacity: 0.6 - (5 - maxStep)*0.1} : {}}>
                <h1 class="hero-block__title">Woohoo! Your order will arrive in 3-5 days. <img style={{width: '46px'}} src="https://www.milacares.com/buy/assets/images/party-popper.png" alt="party-popper" /></h1>
                <div class="hero-block__description">
                    <p>Order today and get yours by
                        <b>{deliveryStr}</b>
                    </p>
                </div>
                <div class="order-section">
                    <form class="contact-form">
                        <h2 class="form-title">Contact information</h2>
                        <div class={`form-group form-group--zindex6 ${errors && errors[0].dataPath === '.customerFullName' ? 'form-group--error' : '' }`}>
                            <input name="customer_name" id="full-name" type="text" class="form-control" placeholder="Type your  full name here ..." value={customer.fullName} onChange={e => set('fullName', e.target.value)} required />
                            <label for="full-name">Full Name</label>
                        </div>
                        <div class={`form-group form-group--zindex5 ${errors && errors[0].dataPath === '.customerEmail' ? 'form-group--error' : '' }`}>
                            <input name="customer_email" id="email-address" type="email" class="form-control" placeholder="Type your email address here ..." value={customer.email} onChange={e => set('email', e.target.value)} required />
                            <label for="email-address">Email Address</label>
                        </div>
                        <div class={`form-group form-group--zindex4 ${errors && errors[0].dataPath === '.customerPhoneNumber' ? 'form-group--error' : '' }`}>
                            <input name="customer_phone_number" id="phone-number" type="text" class="form-control phoneMasking" placeholder="Type your phone number here ..." value={customer.phoneNumber} onChange={phoneChange} required />
                            <label for="phone-number">Phone Number</label>
                        </div>
                    </form>
                    <div class="order-information">
                        <div class="form-toggle">
                            <h2 class={`form-title form-title--shipping ${toggle === 'shipping' ? 'form-title--is-active' : ''}`}><span onClick={() => setToggle('shipping')}>Shipping information</span></h2>
                            <h2 class={`form-title ${customer.differentBillingAddress ? '' : 'form-title--billing'} ${toggle === 'billing' ? 'form-title--is-active' : ''}`}><span onClick={() => setToggle('billing')}>Billing information</span></h2>
                        </div>
                        {toggle === 'shipping' ?  
                            <ShippingForm errors={errors}/>
                            : <BillingForm errors={errors}/>
                            }
                        <div class="checkbox-holder">
                            <div class="switch-checkbox">
                                <input class="switch-checkbox__input" id="billing-form-trigger" type="checkbox" checked={customer.differentBillingAddress} onChange={() => (set('differentBillingAddress', !customer.differentBillingAddress), customer.differentBillingAddress && setToggle('shipping'))}/>
                                <label class="switch-checkbox__label" for="billing-form-trigger"></label>
                            </div>
                            <p class="switch-checkbox__text">My billing address is different.</p>
                        </div>
                    </div>
                </div>
            <StickyNavigation  nextDisabled={errors}>
                {errors && (errors[0].keyword === 'required' ? 
                    <> Don't forget to enter your &nbsp;<b>{getTextFromDataPath(errors[0].dataPath) || `shipping details`}</b>&nbsp;{!getTextFromDataPath(errors[0].dataPath) && `in`} 😉
                    </> :
                    <> Please enter a valid &nbsp;<b>{getTextFromDataPath(errors[0].dataPath)}</b>&nbsp; 😉
                    </>
                )}
            </StickyNavigation>
        </div>)
})

