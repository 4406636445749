import {createStore, combineReducers, applyMiddleware} from 'redux';
import {descriptions} from '../data';
import thunk from 'redux-thunk';
import {cartCalc} from 'm2back/cart';
import preCartReducers from './preCart';


import {loadStripe} from '@stripe/stripe-js';
import {actionDiscountCodeSet, actionDiscountWrong, actionDiscountSet} from './preCart';
export * from './preCart';

export const stripePromise = loadStripe("pk_test_i9zDjW1cCk7FC3cHIUfvOpj500IiTDzUO1",
  {apiVersion: "2020-08-27"});

const backendURL = window.location.hostname === 'asmer.ed.asmer.org.ua' ? 'https://m2.ed.asmer.org.ua' : '';

function localStored(reducer, name='defaultRedux'){
    return (state, action) => {
        if (state === undefined) if (localStorage[name]){
            try {
                let jsonState = JSON.parse(localStorage[name])
                return jsonState;
            }
            catch(e){

            }
        }
        let newState = reducer(state, action)
        localStorage[name] = JSON.stringify(newState)
        return newState
    }
}




const reducers = {
    cart:localStored((state,action)=>{
        const newState = preCartReducers(state,action)

        const {milaCount, autoRefill, existingUser, filters:{filled}} = newState
        newState.maxStep      = 1;
        (milaCount > 0 &&  milaCount < 11 && (
            (newState.maxStep      = 3) && (
                (filled || !autoRefill) && (
                    (newState.maxStep      = 7)
                )
            )
        ))

        if (action.type === 'BACKEND_CART' && action.status === 'RESOLVED' && action.payload.existingUser !== state.existingUser){
            return cartCalc({...newState, existingUser: action.payload.existingUser, maxStep: newState.maxStep})
        }
        if (newState === state) return state;
        newState.existingUser = state && state.existingUser


        //console.log(newState, cartCalc(newState))

        return cartCalc(newState)

    }, 'cart'),
    backendCart:(state={}, {type, status, payload, error}) => {
        if (type === 'BACKEND_CART'){
            return {status, payload, error}
        }
        return state;
    },
    backendOrder:(state={}, {type, status, payload, error}) => {
        if (type === 'BACKEND_ORDER'){
            return {status, payload, error}
        }
        return state;
    },
    route(state={}, {type, match, history}){
        if (type === 'ROUTE'){
            return {
                match, history
            }
        }

        return state;
    },
    resize(state){
        return window.innerWidth > 768 ? 'desktop' : 'mobile';
    },
    paymentMethod(state='stripe', {type}){
        if (type.startsWith('PAYMENT_METHOD_'))
            return type.slice('PAYMENT_METHOD_'.length).toLowerCase()

        return state
    },
    paymentRequest(state=null, {type, pr}){
        if (type === 'PAYMENT_REQUEST')
            return pr

        return state
    }
}

export const actionRoute      = (match, history) => ({type: 'ROUTE', match, history})
export const actionResize        = () => ({type: 'RESIZE'})


export const actionPaymentMethodApple        = () => 
    async dispatch => {
        if (store.getState().backendCart.status !== 'RESOLVED') return;
        
        const amount = store.getState().backendCart.payload.total  * 100
        const {clientSecret} = await fetch(`${backendURL}/order/intent`, {method: 'post'})
                                        .then(res => res.json())
                                        //.then(payload => store.dispatch({type: 'DEFAULT_FILTERS_LOAD', payload}))
        let stripe = await stripePromise
        const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'MilaCares total',
                amount: amount
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        if (await pr.canMakePayment()){
            dispatch({type: 'PAYMENT_REQUEST', pr})
        }

        pr.on('paymentmethod', async (ev) => {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
         //       clientSecret,
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
            );

            if (confirmError) {
                ev.complete('fail');
            } else {
                ev.complete('success');
                if (paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const {error} = await stripe.confirmCardPayment(clientSecret);
                    if (error) {
                        // The payment failed -- ask your customer for a new payment method.
                    } else {
                        // The payment has succeeded.
                    }
                } else {
                    // The payment has succeeded.
                }
            }
        });

        dispatch({type: 'PAYMENT_METHOD_APPLE'})
    }
export const actionPaymentMethodGoogle        = () => ({type: 'PAYMENT_METHOD_GOOGLE'})
export const actionPaymentMethodStripe        = () => ({type: 'PAYMENT_METHOD_STRIPE'})

export const actionDiscount = code =>
   async dispatch => {
        try {
            dispatch(actionDiscountCodeSet(code))
            let discount = await (await fetch(`${backendURL}/discount/code-verify`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({code})
                })).json()
            if (!discount || discount === 'trouble'){
                dispatch(actionDiscountWrong())
            }
            else {
                dispatch(actionDiscountSet(discount.discount))
            }
        }
        catch(e){
            dispatch(actionDiscountWrong())
        }
    }

export const actionBackendCartPending = () => ({type: 'BACKEND_CART', status: 'PENDING'})
export const actionBackendCartResolved = (payload) => ({type: 'BACKEND_CART', status: 'RESOLVED', payload})
export const actionBackendCartError = (error) => ({type: 'BACKEND_CART', status: 'REJECTED', error})

export const actionBackendCart = ({autoRefill, milaCount, sockCount, filters:{filtersCount}, discount: {code:discountCode}, customer: {shippingState, billingState, email: customerEmail}}) =>
   async dispatch => {
        try {
            dispatch(actionBackendCartPending())
            let backendCart = await (await fetch(`${backendURL}/order/cart`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({autoRefill, milaCount, sockCount: sockCount || 0, filtersCount, discountCode: discountCode || undefined, shippingState, billingState, customerEmail })
                })).json()
            if (backendCart === 'trouble') throw new SyntaxError('Cart Data Invalid')
            dispatch(actionBackendCartResolved(backendCart))
        }
        catch(e){
            dispatch(actionBackendCartError(e))
        }
    }


export const actionBackendOrderPending = () => ({type: 'BACKEND_ORDER', status: 'PENDING'})
export const actionBackendOrderResolved = (payload) => ({type: 'BACKEND_ORDER', status: 'RESOLVED', payload})
export const actionBackendOrderError = (error) => ({type: 'BACKEND_ORDER', status: 'REJECTED', error})

export const actionOrder = ({autoRefill, milaCount, sockCount, filters:{filtersCount}, discount: {code:discountCode}, customer: {fullName, email, phoneNumber, shippingAddress, shippingApartment, shippingCity, shippingZipcode, billingAddress, billingApartment, billingZipcode, shippingState, billingState, }}) =>
   async dispatch => {
        try {
            dispatch(actionBackendOrderPending())
            let backendCart = await (await fetch(`${backendURL}/order/`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({autoRefill, milaCount, sockCount, filtersCount, discountCode: discountCode || undefined, 
                            customerFullName: fullName,
                            customerEmail: email,
                            customerPhoneNumber: phoneNumber,
                            shippingAddress, shippingApartment, shippingCity, shippingZipcode, billingAddress, billingApartment, billingZipcode,
                            shippingState, billingState })
                })).json()
            dispatch(actionBackendOrderResolved(backendCart))
        }
        catch(e){
            dispatch(actionBackendOrderError(e))
        }
    }

const store = createStore(combineReducers(reducers), applyMiddleware(thunk))
store.subscribe(() => console.log(store.getState()))
{
    let prevCart;
    store.subscribe(() => {
        const {existingUser, maxStep, ...cart} = store.getState().cart
        if (JSON.stringify(prevCart) !== JSON.stringify(cart)){ //cart update
            prevCart = cart;
            store.dispatch(actionBackendCart(cart))
        }
    })
}

fetch(`${backendURL}/product`)
    .then(res => res.json())
    .then(payload => store.dispatch({type: 'DEFAULT_FILTERS_LOAD', payload}))

window.onresize = () => store.dispatch(actionResize())

export default store
