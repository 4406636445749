import React from 'react';
export default () => 
<footer>
<svg xmlns="http://www.w3.org/2000/svg" style={{height:0,position:"absolute",width:0}}>
    <defs>
        <symbol id="icon-plus" viewBox="0 0 32 32">
            <path d="M16.001 1.625c1.663 0 3.030 1.269 3.185 2.892l0.015 0.308v22.349c0 1.767-1.433 3.2-3.2 3.2-1.663 0-3.030-1.269-3.185-2.892l-0.015-0.308v-22.349c0-1.767 1.433-3.2 3.2-3.2z"></path>
            <path d="M27.175 12.8c1.767 0 3.2 1.433 3.2 3.2 0 1.663-1.269 3.030-2.892 3.185l-0.308 0.015h-22.349c-1.767 0-3.2-1.433-3.2-3.2 0-1.663 1.269-3.030 2.892-3.185l0.308-0.015z"></path>
        </symbol>
        <symbol id="icon-minus" viewBox="0 0 149 32">
            <path d="M125.462 0c8.837 0 16 7.163 16 16 0 8.317-6.345 15.151-14.459 15.927l-1.541 0.073h-101.587c-8.837 0-16-7.163-16-16 0-8.317 6.345-15.151 14.459-15.927l1.541-0.073z"></path>
        </symbol>
        <symbol id="icon-tick" viewBox="0 0 44 32">
            <path d="M36.822 2.265c1.468-1.415 3.805-1.373 5.221 0.095 1.327 1.376 1.373 3.517 0.164 4.945l-0.259 0.276-22.974 22.154c-1.341 1.293-3.416 1.374-4.848 0.242l-0.278-0.242-11.487-11.077c-1.468-1.415-1.51-3.753-0.095-5.221 1.327-1.376 3.464-1.5 4.936-0.343l0.285 0.248 8.923 8.605z"></path>
        </symbol>
        <symbol id="icon-basket" viewBox="0 0 41 32">
            <path d="M35.288 7.619h-29.433c-2.974 0-5.156 2.795-4.435 5.68l3.81 15.238c0.509 2.035 2.337 3.463 4.435 3.463h21.814c2.098 0 3.926-1.428 4.435-3.463l3.81-15.238c0.721-2.885-1.461-5.68-4.435-5.68zM31.478 27.429h-21.814l-3.81-15.238h29.433z"></path>
            <path d="M25.905 0h-10.667c-2.525 0-4.571 2.047-4.571 4.571v7.619h19.81v-7.619c0-2.525-2.047-4.571-4.571-4.571zM15.238 4.571h10.667v3.048h-10.667z"></path>
        </symbol>
        <symbol id="icon-arrow-down" viewBox="0 0 60 32">
            <path d="M48.342 3.244c2.627-2.020 6.394-1.529 8.414 1.097 1.902 2.472 1.578 5.954-0.656 8.040l-0.441 0.374-22 16.923c-1.991 1.531-4.703 1.649-6.805 0.353l-0.512-0.353-22-16.923c-2.627-2.020-3.118-5.787-1.097-8.414 1.902-2.472 5.35-3.053 7.939-1.428l0.475 0.331 18.342 14.108z"></path>
        </symbol>
    </defs>
</svg>
</footer>
